import React from 'react';
import '../../App.css';
import CardProfile from '../CardProfile';
import img_cg from '../../assets/images/profile_cg_800.jpg'
import img_dg from '../../assets/images/profile_dg_800-min.jpg'

import imageAboutVision from '../../assets/images/image-about-vision-min.jpg'
import imageAboutMission from '../../assets/images/image-about-mission-min.jpg'
import imageAboutPrinciples from '../../assets/images/image-about-principles-min.jpg'

import {ReactComponent as IcoLetterA} from '../../assets/images/letter-a.svg'
import {ReactComponent as IcoLetterD} from '../../assets/images/letter-d.svg'
import {ReactComponent as IcoLetterI} from '../../assets/images/letter-i.svg'
import {ReactComponent as IcoLetterS} from '../../assets/images/letter-s.svg'


import {Icon} from '../Icon';

import { ListCustomBullet } from '../ListCustomBullet';

import Quote from '../Quote'

const descDani = <span>Profesional con experiencia en trastornos del lenguaje y neurodiversidad; con formación en método <abbr tabIndex='0' className='cursor-help' title='Tratamiento y Educación de Niños con Autismo y Problemas Asociados a la Comunicación'><ruby>
<rb>TEACCH</rb>
<rp>(</rp>
<rt>Tich</rt>
<rp>)</rp>
</ruby></abbr>, terapia <abbr tabIndex='0' className='cursor-help' title='Análisis del Comportamiento Aplicado'>ABA</abbr>, herramientas para educación inclusiva y gestión educativa para la inclusión.</span>

const profiles = 
    [
        {
            src: img_dg,
            alt: 'primer plano de Daniela Gonzales',
            name: 'Daniela Gonzales',
            title: 'Fonoaudióloga',
            subtitle: 'Cofundadora',
            description: descDani,
            url: 'https://cl.linkedin.com/in/daniela-gonzales-949159b0',
            css: 'cp-card'
        },
        {
            src: img_cg,
            alt: 'primer plano de Cristian Gonzales',
            name: 'Cristian Gonzales',
            title: 'Ingeniero en Informática',
            subtitle: 'Cofundador',
            description: 'Profesional certificado por la Asociación Internacional de Profesionales de Accesibilidad (IAAP); con experiencia en desarrollo de productos digitales y gestión de servicios informáticos.',
            url: 'https://cl.linkedin.com/in/cristian-gonzales',
            css: 'cp-card'
        }

    ]

    

    const paragraphD = <div style={{alignItems: 'center', display: 'flex',flexDirection: 'column'}}><p className='p-large-left'><span className='bold'>Diversidad</span></p><p className='p-large-left' style={{textAlign: 'center', marginTop: '5px'}}>Reconocer la diversidad</p></div>
    const paragraphI = <div style={{alignItems: 'center', display: 'flex',flexDirection: 'column'}}><p className='p-large-left'><span className='bold'>Inclusión</span></p><p className='p-large-left' style={{textAlign: 'center', marginTop: '5px'}}>Trabajar por la inclusión</p></div>
    const paragraphA = <div style={{alignItems: 'center', display: 'flex',flexDirection: 'column'}}><p className='p-large-left'><span className='bold'>Accesibilidad</span></p><p className='p-large-left' style={{textAlign: 'center', marginTop: '5px'}}>Promover la accesibilidad</p></div>
    const paragraphS = <div style={{alignItems: 'center', display: 'flex',flexDirection: 'column'}}><p className='p-large-left'><span className='bold'>Sinergia</span></p><p className='p-large-left' style={{textAlign: 'center', marginTop: '5px'}}>Generar sinergia</p></div>

    const principles = 
    [
        {
            id: 1000,
            name: paragraphD,
            description: '',
            icon: IcoLetterD,
        },
        {
            id: 1001,
            name: paragraphI,
            description: '',
            icon: IcoLetterI,
        },
        {
            id: 1002,
            name: paragraphA,
            description: '',
            icon: IcoLetterA,
        },
        {
            id: 1003,
            name: paragraphS,
            description: '',
            icon: IcoLetterS,
        }

    ]

// Function component using function keyword
function AboutUs() {
    window.scrollTo({top: 0});

    const principlesLi = 
        <ListCustomBullet
        listType='ol'
        listTitle=''
        listSize={'list--simple'}
        className={'list-letters'}
        children={principles.map(({id, name, icon}) =><li className='list--size--letters' key={id}><Icon cssClassIcon='icon-aboutus' Image={icon} />{name}</li>)}>
        </ListCustomBullet>

    const principlesP = <div><p><span className='bold'>DIAS</span> nace a partir de la idea compartida por nuestros fundadores de <span className='bold'>ayudar a construir una sociedad más inclusiva</span> y desarrolla su labor siguiendo <span className='bold'>cuatro principios</span>:</p>{principlesLi}</div>;


    return (
        <>
        <div className='container-parent margin-bottom-xl'>
            <Quote className='blockquote--secondary'
                    quote='Somos DIAS, una empresa especializada en servicios de accesibilidad digital que, a través del trabajo colaborativo, busca fomentar la equidad digital y así ayudar a construir una sociedad más inclusiva.'
                    author='Quienes somos'
                />
            <div className='container-child'>
                <div className='div-center'>
                    <h1 className='heading-text-aboutus w-dynamic margin-top-xl margin-bottom-s'>
                        Nuestra Visión
                    </h1>
                    <img src={imageAboutVision} alt='primer plano del ojo de una persona mirando de frente' className='w-dynamic margin-bottom-s' />
                    <p className='p-large-left w-dynamic margin-top-s'>
                        Ser el <span className='bold'>socio estratégico</span> de aquellas organizaciones interesadas en ofrecer <span className='bold'>productos digitales accesibles</span>. Queremos una <span className='bold'>sociedad más inclusiva</span> por lo que trabajamos para <span className='bold'>eliminar las barreras digitales</span>.
                    </p>
                    
                    <h1 className='heading-text-aboutus w-dynamic margin-top-xl margin-bottom-s'>
                        Nuestra Misión
                    </h1>
                    <img src={imageAboutMission} alt='nave espacial despegando' className='w-dynamic margin-bottom-s' />
                    <p className='p-large-left w-dynamic margin-top-s'>
                        Entregar <span className='bold'>servicios profesionales de accesibilidad digital</span> que ayuden a las organizaciones a generar productos digitales que brinden una <span className='bold'>mejor experiencia de usuario</span> y puedan ser <span className='bold'>usados por el mayor número de personas</span>.
                    </p>

                    <h1 className='heading-text-aboutus w-dynamic margin-top-xl margin-bottom-s'>
                        Nuestros Principios
                    </h1>
                    <img src={imageAboutPrinciples} alt='un grupo de personas que forman un círculo apoyan sus manos entre sí' className='w-dynamic margin-bottom-s' />
                    <div className='p-large-left w-dynamic margin-top-s'>
                        {principlesP}
                    </div>

                    <h1 className='heading-text-aboutus w-dynamic'>
                        Nuestros Fundadores
                    </h1>
                </div>

                <div className="capp-cards w-60">
                {profiles.map((card, index) => {
                    return (
                        <CardProfile key={index}
                        src={card.src}
                        name={card.name}
                        alt={card.alt}
                        title={card.title}
                        subtitle={card.subtitle}
                        description={card.description}
                        url={card.url}
                        css={card.css}
                        />)
                    }
                )}
                </div>
            </div>
        </div>
        </>
    )
}

export default AboutUs