import React from 'react';
import '../../App.css';

/*
import {ReactComponent as IcoServicesAudit} from '../../assets/images/ico_services_audit.svg'
import {ReactComponent as IcoServicesConsultancy} from '../../assets/images/ico_services_consultancy.svg'
import {ReactComponent as IcoServicesRemediation} from '../../assets/images/ico_services_remediation.svg'
*/

import imageServicesAudit from '../../assets/images/image-audit-min.jpg'
import imageServicesConsultancy from '../../assets/images/image-consulting-min.jpg'
import imageServicesRemediation from '../../assets/images/image-remediation-min.jpg'


import { ListCustomBullet } from '../ListCustomBullet';


const paragraphConsultancy = <p className='p-large-left w-dynamic margin-top-s margin-bottom-s'><span className='bold'>Asesoría especializada</span> durante la etapa de análisis y diseño, con el objeto de identificar todos los requisitos que se deben satisfacer para desarrollar un <span className='bold'>producto digital accesible</span>.</p>
//const paragraphAudit = <p className='p-large-left w-60 margin-bottom-s'><span className='bold'>Evaluación del nivel de accesibilidad</span> de un producto digital, conforme a los criterios de las WCAG, con el objeto de <span className='bold'>identificar el nivel de accesibilidad</span> ofrecido y las <span className='bold'>falencias o errores a subsanar</span>.</p>
const paragraphAudit = <p className='p-large-left w-dynamic margin-top-s margin-bottom-s'><span className='bold'>Evaluación</span> de un producto digital (sitio web, aplicación móvil, o documento digital) con el objeto de identificar el <span className='bold'>nivel de accesibilidad</span> ofrecido y las <span className='bold'>falencias o errores</span> a subsanar.</p>
const paragraphRemediation = <p className='p-large-left w-dynamic margin-top-s margin-bottom-s'><span className='bold'>Implementación</span> de los ajustes y correcciones necesarias para <span className='bold'>mejorar el nivel de accesibilidad</span> de un producto digital.</p>


const services = 
    [
        {
            id: 10,
            Image: imageServicesConsultancy,
            name: 'Consultoría',
            alt: 'dos personas frente a un computador portátil, una de ellas le indica a la otra algo en la pantalla',
            type: '',
            description: paragraphConsultancy,
            subdescription: '',
            url: '#',
            css: 'cs-card',
            cssClassIcon: 'li-icon',
            process: [
                {
                    id: 11,
                    name: 'Evaluación de Requerimientos Funcionales'
                },
                {
                    id: 12,
                    name: 'Revisión de Propuesta de Diseño'
                },
                {
                    id: 13,
                    name: 'Identificación de Requisitos de Accesibilidad'
                }
            ]
        },
        {
            id: 20,
            Image: imageServicesAudit,
            name: 'Auditoría',
            alt: 'una persona sostiene una lupa sobre un computador portátil',
            type: '',
            description: paragraphAudit,
            subdescription: '',
            url: '#',
            css: 'cs-card',
            cssClassIcon: 'li-icon',
            process: [
                {
                    id: 21,
                    name: 'Definición de Alcance y Selección de Estándar'
                },
                {
                    id: 22,
                    name: 'Evaluación de Criterios de Cumplimiento'
                },
                {
                    id: 23,
                    name: 'Generación de Reporte de Auditoría'
                }
            ]
        },
        {
            id: 30,
            Image: imageServicesRemediation,
            name: 'Remediación',
            alt: 'una persona usando un computador portátil, en la pantalla se ve una aplicación de programación',
            type: '',
            description: paragraphRemediation,
            subdescription: '',
            url: '#',
            css: 'cs-card',
            cssClassIcon: 'li-icon',
            process: [
                {
                    id: 31,
                    name: 'Definición de Alcance y Evaluación Técnica'
                },
                {
                    id: 32,
                    name: 'Implementación y Validación'
                },
                {
                    id: 33,
                    name: 'Generación de Reporte de Remediación'
                }
            ]
        }

    ]

const servicesLista = services.map(({ id, name, alt, description, Image, cssClassIcon, process }) =>
    <li className='list-style-none' key={id}>
        <div className='div-center'>
        <h1 className='heading-text-aboutus w-dynamic margin-bottom-s'>
            {name}
        </h1>
        {/*
        <Icon cssClassIcon='icon-aboutus' Image={Image} cssClassName='margin-top-m margin-bottom-s' />
        */}
        <img src={Image} alt={alt} className='w-dynamic margin-bottom-s' />
        {description}
        <ListCustomBullet
        listType='ol'
        listTitle='Etapas:'
        listSize={'list--simple'}
        className={'w-dynamic'}
        children={process.map(c => <li className='list--size-m' key={c.id}>{c.name}</li>)}>
        </ListCustomBullet>
        </div>
    </li>);

// Function component using function keyword
function Services() {
    window.scrollTo({top: 0});
    return (
        <>
        <div className='container-parent'>
            <div className='container-child align-center'>
                <p className='w-dynamic align-center p-large-left margin-top-paragraph margin-bottom-paragraph'>
                    Con el objeto de satisfacer las necesidades de accesibilidad digital de manera integral, en DIAS ofrecemos los siguientes servicios:
                </p>
                <ul>
                    {servicesLista}
                </ul>
            </div>
        </div>
        </>
    )
}

export default Services